// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDu-N7A2KcYTJc8UpMWjQvzrTGZvupnwpA",
    authDomain: "balancewise.com.br",
    projectId: "balancewise-com-br",
    storageBucket: "balancewise-com-br.appspot.com",
    messagingSenderId: "688193586515",
    appId: "1:688193586515:web:8c96aa0ddf8dddbc1d4da6",
    measurementId: "G-4633EJEPZX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
